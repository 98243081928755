import React, { useEffect, useState } from "react";
import axios from "axios";


const Rough3 = ()=>{

    const [jobsList, setJobList] = useState([]);
    
    const jobListData = ()=>{
        axios.get('https://primebuk.com/easybukapi/web/job/list')
  .then(function (response) {
    // handle success
    setJobList(response.data.response_data.data)
    console.log(response);
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });
    }
    useEffect(()=>{
        jobListData();
    },[])
    return(
        <>
<div className="grid grid-cols-3">
        {jobsList?.map((i)=>{
return(
    <>
    <div className="shadow-lg p-4">
    <img src={i.cover}/>
    {i.name}
    <p>{i.address}</p> 
    <p>{i.address_location}</p>
    <p>{i.description}</p>
    <p>{i.email}</p>
    </div>
    
    </>
   )

            })
        }
    
       
    </div>
        </>
    )
}
export default Rough3;