// import React, {useState, useEffect} from "react";
// import {Link, useNavigate} from "react-router-dom";
// import axios from "axios";

// const Rough2 = ()=>{

//     const [productsList, setProducts] = useState([]);

//     const fetchProducts = async ()=>{
//         const res = await axios.get('https://primebuk.com/easybukapi/web/restaurant/nearby_restaurant/26.8894208/80.953344/20/4');
//         setProducts(res.data);
//     };

// useEffect(()=>{
//     fetchProducts();
// },[]);

//     return (
//         <>
//       <div>
//         {
//         productsList?.products?.map((i)=>{
//             return(
//                 <>
//               <p>{i.title}</p>  
//               <p>{i.description}</p>
//                 </>
//             );
//         })
//         }
//         </div>
//         </>
//     )
// }

// export default Rough2;




import React, { useState, useEffect } from "react";
import axios from "axios";

const Rough2 = () => {
  const [productsList, setProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://primebuk.com/easybukapi/web/restaurant/nearby_restaurant/26.8894208/80.953344/20/4"
        );
        console.log('responseData==>',response.data.response_data.data)
        setProducts(response.data.response_data.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProducts();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {productsList.map((product) => (
        <div>
            <p>{product.restaurant_address}</p>
      
        </div>
      ))}
    </div>
  );
};

export default Rough2;

