import React from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import news1 from '../images/news1.png';
import news2 from '../images/news2.jpg';
import news3 from '../images/news3.jpg';
import { FaTwitter, FaFacebook, FaInstagram,FaHeadphones,FaEnvelope,FaApple, FaAmazon, FaEbay, FaGooglePlay, FaRegUserCircle,FaTwitch,FaLowVision,FaVision } from "react-icons/fa";


const News = ()=>{
    return (
        <>
                <div className=" flex justify-center gap-2 pt-20 text-4xl font-bold">
        <p className="" >Latest</p> <p className="text-[#17A2B8]">News</p>
        </div>
        <p className="text-center">Get the latest News from our work, writing, discuss and share</p>
        <p className="text-4xl text-center py-5">POPULAR NEWS</p>
        
<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 px-10 justify-items-center">
<div>
  
<Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 240 }}
        image={news1}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Top Restaurents
        </Typography>
        <Typography variant="body2" color="text.secondary">
          With Primebuk you can dine at best restaurents at the cheapest price at your nearest outlet,Checkout now.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </div>


    <div>
<Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 240 }}
        image={news2}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Top Restaurents
        </Typography>
        <Typography variant="body2" color="text.secondary">
          With Primebuk you can dine at best restaurents at the cheapest price at your nearest outlet,Checkout now.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </div>





    <div>
<Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 240 }}
        image={news3}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Top Restaurents
        </Typography>
        <Typography variant="body2" color="text.secondary">
          With Primebuk you can dine at best restaurents at the cheapest price at your nearest outlet,Checkout now.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </div>




    <div>
<Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 240 }}
        image={news1}
        title="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Top Restaurents
        </Typography>
        <Typography variant="body2" color="text.secondary">
          With Primebuk you can dine at best restaurents at the cheapest price at your nearest outlet,Checkout now.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    </div>


</div>
<div className="mx-10 pt-8">
<hr/>
</div>
{/* Section2 start */}
<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-20 mx-10 justify-items-center">
<div>
    <img src={news2} className="rounded-lg"/>
</div>
<div className="col-span-3">
    <p className="text-3xl font-semi-bold">Check all latest news of primebuk from our website on daily basis and keep updated yourself.</p>
    <p>With Primebuk you can dine at best restaurents at the cheapest price at your nearest outlet,Checkout now.</p>
    <div className="grid grid-cols-3">
    <div className="flex gap-1"><FaRegUserCircle className="text-xl"/> <p> By</p></div>
    <div className="flex justify-end gap-4">
   <div className="flex gap-1"> <FaTwitch className="text-xl"/> <p>23</p></div>
   <div className="flex gap-1"> <FaLowVision className="text-xl"/> <p>123</p></div>
   
   </div>
   
    </div>

</div>
</div>

{/* Section2 end */}


        </>
    )
}
export default News;