import React from "react";
import { Carousel } from 'react-bootstrap';
import job3 from '../images/job3.jpg';
import job4 from '../images/job4.jpg';
import job6 from '../images/job6.jpg';
import job7 from '../images/jobs.jpg';
import { useEffect, useState } from "react";
import axios from "axios";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
const Jobs = ()=>{

  const [jobsList, setJobList] = useState([]);
    
  const jobListData = ()=>{
      axios.get('https://primebuk.com/easybukapi/web/job/list')
.then(function (response) {
  // handle success
  setJobList(response.data.response_data.data)
  console.log(response);
})
.catch(function (error) {
  // handle error
  console.log(error);
})
.finally(function () {
  // always executed
});
  }
  useEffect(()=>{
      jobListData();
  },[])
  return(
      <>

  


   {/* <!-- Main body start -->


<!-- Section2 start --> */}
   <div>
      
      <Carousel fade interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={job3}
            alt="Slide 1"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={job4}
            alt="Slide 2"/>

        </Carousel.Item>

                <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={job6}
            alt="Slide 2"
          />

        </Carousel.Item>
        {/* Add more Carousel.Items as needed */}
      </Carousel>
    </div>
{/* <!-- Section2 end -->
<!-- Section1 start --> */}
<section>
    <div class="container job_container" style={{boxShadow:'1px 1px 9px 1px'}}>
        <p class="p1">Unlock Your Potential and Shape the Future with Us!</p>
        <p class="p2">Join our dynamic team and be a part of an exciting journey where your skills and ideas will make a difference. We are seeking talented individuals who are passionate about innovation, ready to challenge the status quo, and committed to driving positive change in their respective fields. Together, let's unlock our full potential and shape a brighter future for our organization and the world. Apply now and embark on a rewarding career filled with opportunities for growth, learning, and impactful contributions!</p>
    </div>
</section>

<p className="mx-10 text-3xl font-bold">Current Openings</p>
<div className="grid grid-cols-3 mx-10">
      {jobsList?.map((i)=>{
return(
  <>


  <div className="shadow-lg p-4">
  <img src={job7}/>
  <p className="text-2xl font-semibold">{i.job_title}</p>
  <p>{i.type_title}</p> 
  <p><LocationOnIcon/>{i.address_location}</p>
  <p><CurrencyRupeeIcon/>{i.job_salary}</p>
  <p><OpenInNewIcon/>{i.apply_url}</p>
  </div>
  
  </>
 )

          })
      }
  
     
  </div>



{/* <section>
    <div class="container job_section3">
        <p class="p1">Current Openings</p>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

            <h4>{i.job_title}</h4>
        <i class="fa-solid fa-briefcase"></i>&nbsp; <span> Full-Time</span><br/>
        <i class="fa-solid fa-clock"></i>&nbsp;<span> Posted On: 12/04/2021 05:22 PM</span><br/>
        <i class="fa-solid fa-location-dot"></i>&nbsp;<span>  Vikas Nagar, Lucknow, Uttar Pradesh, India</span><br/>
        <i class="fa-solid fa-indian-rupee-sign">&nbsp;</i> <span> 54879 P/M</span><br/>
        <i class="fa-solid fa-link"></i> <span> https://primebook.com/jobs</span><br/>
        <a href="" >More..</a>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <h4>Human Resource</h4>
        <i class="fa-solid fa-briefcase"></i>&nbsp; <span> Full-Time</span><br/>
        <i class="fa-solid fa-clock"></i>&nbsp;<span> Posted On: 12/04/2021 05:22 PM</span><br/>
        <i class="fa-solid fa-location-dot"></i>&nbsp;<span>  Vikas Nagar, Lucknow, Uttar Pradesh, India</span><br/>
        <i class="fa-solid fa-indian-rupee-sign">&nbsp;</i> <span> 54879 P/M</span><br/>
        <i class="fa-solid fa-link"></i> <span> https://primebook.com/jobs</span><br/>
        <a href="" >More..</a>
            </div>
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <h4>Customer Care Executive</h4>
        <i class="fa-solid fa-briefcase"></i>&nbsp; <span> Full-Time</span><br/>
        <i class="fa-solid fa-clock"></i>&nbsp;<span> Posted On: 12/04/2021 05:22 PM</span><br/>
        <i class="fa-solid fa-location-dot"></i>&nbsp;<span>  Vikas Nagar, Lucknow, Uttar Pradesh, India</span><br/>
        <i class="fa-solid fa-indian-rupee-sign">&nbsp;</i> <span> 54879 P/M</span><br/>
        <i class="fa-solid fa-link"></i> <span> https://primebook.com/jobs</span><br/>
        <a href="" >More..</a>
            </div>

        </div>
        
    </div>
</section> */}



      </>


)

}
export default Jobs;