import React from "react";
import primebuklogo from "../images/Primebuk-Logo-1.png";
import androidownload from "../images/androiddownload.png";
import iosdownload from "../images/iosdownload.png";
import { FaTwitter, FaFacebook, FaInstagram,FaHeadphones,FaEnvelope,FaApple, FaAmazon, FaEbay, FaGooglePlay,FaMicrosoft } from "react-icons/fa";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// import Icon from '@mui/material/Icon';

import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* // <!-- footer Section --> */}

      {/* <div classNameName="container-fluid "> */}

      {/* <div className="grid grid-cols-2 ">
    <div className="footer_row1" >
    <a href="">Terms & Conditions</a><a href=""> Privacy Policy</a>
    </div> */}

      {/* </div> */}
   

<section className="pt-16">
  <div className="bg-[#17A2B8] py-2">
            <div className="grid grid-cols-2   gap-4 px-2 ">
              
            
            <div className=" flex gap-3">
            <NavLink className='text-white' style={{textDecoration:'none'}}>Terms & Conditions</NavLink>
            <NavLink className='text-white' style={{textDecoration:'none'}}>Privacy Policy</NavLink>
            </div>
            
            <div className=" flex justify-end gap-2">
            <NavLink className='text-white text-3xl ' style={{textDecoration:'none'}}><FaApple/></NavLink>
            <NavLink className='text-white text-3xl ' style={{textDecoration:'none'}}><FaAmazon/></NavLink>
            <NavLink className='text-white text-3xl ' style={{textDecoration:'none'}}><FaMicrosoft/></NavLink>
            <NavLink className='text-white text-3xl ' style={{textDecoration:'none'}}><FaGooglePlay/></NavLink>
            
            </div>
           
            </div>

            {/* <div className="col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 footer_row1_col1">
              &nbsp; &nbsp; &nbsp; <i className="fa-brands fa-apple"></i>&nbsp;
              &nbsp; &nbsp;
              <i className="fa-brands fa-windows"></i>&nbsp; &nbsp; &nbsp;
              <i className="fa-brands fa-android"></i>
            </div> */}
            </div>
<div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4 lg:grid-cols-4 mx-10 py-5">
<div>
<img
              src={primebuklogo}
              alt=""
              width="200px"
              style={{
                boxShadow: "1px 1px 9px 1px",
                backgroundColor: "#17A2B8",
              }}
            />
            <p className="pt-4">An exercise of willpower. It's tempting to get distracted by the
              bells and whistles of the design process, and forget all about
              creating compelling</p>
              <p>TP 17/400, Pullurampara, Thiruvampady, Kozhikode, Kerala, 673603</p>
</div>
<div>
<ul>
  
              <p className="font-bold">CUSTOMER SERVICE</p>
            
              <li className="pb-3  hover:!text-orange-200">
                <NavLink className="text-black no-underline ">About Us</NavLink>
                
              </li>
              <li className="pb-3">
                <NavLink className="text-black no-underline ">FAQs</NavLink>
                
              </li>
              <li>
                <NavLink className="text-black no-underline ">Contact Us</NavLink>
                
              </li>
             
            </ul>
</div>
<div>
<ul>
              <p className="font-bold">MANAGE</p>
             
              <li className="pb-3">
                <NavLink className="text-black no-underline ">Track My Order</NavLink>
                
              </li>
              <li className="pb-3">
                <NavLink className="text-black no-underline ">My Orders</NavLink>
               
              </li>
              <li className="pb-3">
                <NavLink className="text-black no-underline ">My Account</NavLink>
                
              </li>
              <li>
                <NavLink className="text-black no-underline ">My Wishlist</NavLink>
                
              </li>
             
            </ul>
</div>
<div>
<ul>
              <p className="font-bold">ABOUT US</p>
             
              <li className="pb-3">
                <NavLink className="text-black no-underline " to="">About Easybuk</NavLink>
                
              </li>
              <li className="pb-3">
                <NavLink className="text-black no-underline " to="">Careers</NavLink>
                
              </li>
              <li className="pb-3">
                <NavLink className="text-black no-underline " to="">Terms And Conditions</NavLink>
                
              </li>
              <li>
                <NavLink className="text-black no-underline " to="">Privacy Policy</NavLink>
                
              </li>
              
            </ul>
</div>
</div>


        {/* <div className="row footer_row2 px-3 pt-3">
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <img
              src={primebuklogo}
              alt=""
              width="200px"
              style={{
                boxShadow: "1px 1px 9px 1px",
                backgroundColor: "#17A2B8",
              }}
            />
            <br />
            <br />
            <span>
              An exercise of willpower. It's tempting to get distracted by the
              bells and whistles of the design process, and forget all about
              creating compelling
            </span>
            <span>
              <br />
              TP 17/400, Pullurampara, Thiruvampady, Kozhikode, Kerala, 673603
            </span>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <ul>
              <p>CUSTOMER SERVICE</p>
              <li>
                <a href="">About Us</a>
              </li>
              <li>
                <a href="">FAQs</a>
              </li>
              <li>
                <a href="">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <ul>
              <p>MANAGE</p>
              <li>
                <a href="">Track My Order</a>
              </li>
              <li>
                <a href="">My Orders</a>
              </li>
              <li>
                <a href="">My Account</a>
              </li>
              <li>
                <a href="">My Wishlist</a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <ul>
              <p>ABOUT US</p>
              <li>
                <a href="">About Easybuk</a>
              </li>
              <li>
                <a href="">Careers</a>
              </li>
              <li>
                <a href="">Terms And Conditions</a>
              </li>
              <li>
                <a href="">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div> */}
        <div className= ' px-3 py-3 '>

          <div className="grid grid-cols-1 justify-items-center  sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4  xl:grid-cols-4 gap-1">
            <div className="flex gap-2 ">
              <div><FaHeadphones className="text-5xl "/></div>
            <div>
            <h4 >Call US:</h4>
            <p>91 730-697-2119</p>
            </div>
              
              
            </div>
            <div className="flex gap-2">
              <div><FaEnvelope className="text-5xl"/></div>
<div>
<h4 className="">Email US: </h4>
<p>Info@Primebuk.Com</p>
</div>
              
              <p></p>
            </div>
            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 text-4xl text-black-400 gap-5">
             
            
             
            
              <NavLink className='text-black' to='https://www.facebook.com'>
                <FaFacebook />
              </NavLink>

              <NavLink className='text-black'  to='https://www.twitter.com'>
                <FaTwitter />
              </NavLink>
              <NavLink className='text-black'  to='https://www.instagram.com'>
                <FaInstagram />
              </NavLink>
              
              
            </div>
            <div className="flex gap-5 justify-end  sm:pt-5">
            
              <NavLink>
                <img src={iosdownload} alt="" width="100%" height="auto" />
                </NavLink>
               
           
              <NavLink>
                <img src={androidownload} alt="" width="100%" height="auto" />
                </NavLink>
               
                </div>
                
          </div>
        </div>
        <div
      className="fixed bottom-4 right-4 p-3 bg-[#17A2B8] rounded-full text-white cursor-pointer hover:bg-green-600">
      <NavLink to="https://wa.me/918881810100"><WhatsAppIcon className="!text-4xl text-white"/></NavLink>

      {/* <a href="https://wa.me/918881810100"><i style="font-size:80px; color:green;float:right;position:fixed;border-radius:20px;" class="fa-brands fa-square-whatsapp"></i></a> */}
    </div>

        <div
          className="text-center text-white" style={{ backgroundColor: "#17A2B8" }}>
          <div className="container py-3">
            <p className="pt-3">
              Copyright 2020 Primebuk Developed By: Aaratechnologies Pvt Ltd
            </p>
          </div>

        </div>
      </section>
    </>
  );
};

export default Footer;
