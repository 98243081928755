import React from "react";
import { Carousel } from 'react-bootstrap';
import suplybanner2 from '../images/suplybanner2.jpg'; 
import suplybanner3 from '../images/suplybanner3.png';
import suplybanner1 from '../images/suplybanner1.png';
import { useEffect, useState } from "react";
const Supplies = ()=>{
  const [productList, setProductList] = useState([]);

  const setProductListData = ()=>{
    fetch('https://dummyjson.com/products')
    .then((res) => res.json())
    .then((res)=>{
      setProductList(res);
    });
  };

  useEffect(()=>{
    setProductListData();
  },[]);
  console.log(productList?.products);
    return(
        <>
   <div>
      
      <Carousel fade interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={suplybanner2}
            alt="Slide 1"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={suplybanner3}
            alt="Slide 2"/>

        </Carousel.Item>

                <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={suplybanner1}
            alt="Slide 2"
          />

        </Carousel.Item>
        {/* Add more Carousel.Items as needed */}
      </Carousel>
    </div>

    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4  gap-5 px-5 py-5'>
{
productList?.products?.map((i)=>{
  return(
  <>
  
  <div className='shadow-xl border-2  p-4  my-1 hover:shadow-[1px_1px_9px_1px]'>  
    <img src={i.thumbnail} className=' w-full h-56 pb-2 rounded-lg  object-center'/>
    <p className='font-bold text-lg'>{i.brand}</p>
    <p className='font-semibold text-md text-right'>{i.title}</p>
    <div className="flex"><span className="pr-2 text-md font-bold">Price:-</span><p className='text-green-500'> ₹{i.price}</p></div>
    
    <p>{i.description}</p>
    </div>
 
  

  </>
  )
})
}
</div>




        </>
    )
}


export default Supplies;