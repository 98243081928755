import React, { useEffect, useState } from "react";
import img2 from "../images/cr2img2.jpg";
import img3 from "../images/cr1img1.jpg";
import becomepro from "../images/become a pro.jpg";
import taxi1 from "../images/taxibenifits.jpg";
import taxi2 from "../images/taxibanner.jpg";
import restra1 from "../images/rest11.jpg";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import LocationOnIcon from '@mui/icons-material/LocationOn';
const Blog = () => {
  const [blogList,setblogList]=useState([])

  const blogdata = ()=>{
    axios
      .get(
        "https://primebuk.com/easybukapi/web/restaurant/nearby_restaurant/26.8894208/80.953344/20/4",
        {store_id: 3},

        {
          headers: {
            token: localStorage.getItem("token"),
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        setblogList(res.data.response_data.data);
      })
      .catch((err) => {
        console.log("ERROR: ====", err);
      });
  }
  useEffect(()=>{
    blogdata();
  },[])
  return (
    <>
      {/* <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
  <div class="md:flex gap-4">
    <div class="md:shrink-0">
      <img class="h-48 w-full object-contain md:h-full md:w-48" src={img2} alt="Modern building architecture"/>
    </div>
    <div class="md:shrink-0">
      <img class="h-48 w-full object-cover md:h-full md:w-48" src={img2} alt="Modern building architecture"/>
    </div>
    <div class="p-8">
      <div class="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Company retreats</div>
      <a href="#" class="block mt-1 text-lg leading-tight font-medium text-black hover:underline">Incredible accommodation for your team</a>
      <p class="mt-2 text-slate-500">Looking to take your team away on a retreat to enjoy awesome food and take in some sunshine? We have a list of places to do just that.</p>
    </div>
  </div>
</div> */}

      <div className=" flex justify-center gap-2 pt-20 text-4xl font-bold">
        <p className="">Our </p> <p className="text-[#17A2B8]">Jounral</p>
      </div>
      <p className="text-center">
        Get the latest articles from our journal, writing, discuss and share
      </p>
      <p className="text-4xl text-center py-5">POPULAR POSTS</p>
      {/* <div className="flex flex-row px-10 items-center gap-5 pb-5"> */}
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 px-20 justify-items-center gap-5 ">
        {
          blogList?.map((i)=>{
            return(
              <>
<div className="p-4 shadow-lg">
  <img src={restra1}/>
  <p className="text-2xl font-bold py-2">{i.restaurant_name}</p>
  <p>{i.restaurant_description}</p>
  <LocationOnIcon/> <Button size="small">{i.restaurant_address}</Button>
</div>
            
            {/* <Card className="p-2 mb-4" sx={{ maxWidth: 345 }}>
            <CardMedia className="w-full"
              sx={{ height: 240 }}
              image={restra1}
              title={i.restaurant_name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {i.restaurant_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">

                {i.restaurant_description}
              </Typography>
            </CardContent>
            <CardActions>
            <LocationOnIcon/> <Button size="small">{i.restaurant_address}</Button>
            <LocationOnIcon/> <Button size="small">{i.restaurant_address}</Button>
            </CardActions>
          </Card> */}
          </>
            )
          })
        }

        {/* <div className=" border border-2 shadow-md overflow-hidden">
<img src={img2} className="w-full"/>
<p className="text-center text-xl font-bold py-3">Primebuk Home Services</p>
<p></p>
</div> */}

        {/* <div className="border border-2 shadow-md overflow-hidden max-w-md">
    <div>
<img src={img3} className="w-full shrink-0"/>
</div>
<p className="text-center text-xl font-bold py-3">Primebuk Digital Services</p>

</div> */}

       
      </div>
      <div className="py-2 px-10">
        <hr />
      </div>

      {/* section start */}

      <section className="px-10">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 col-span-2">
            <div>
              <img src={img2} className="rounded-2xl" />
            </div>
            <div className="col-span-1.5">
              <p className="text-xl font-semibold">
                find Out Why Primebuk is the best resource for you.
              </p>
              <p>
                From cleaners to handymen to smart home installers, Primebuk is
                always looking for service professionals who are experts in
                their trade and provide great service to their customers. The
                best home service professionals use Handy to find jobs with no
                lead fees and flexible scheduling.
              </p>
            </div>
          </div>
          <div>
            <p className="text-xl">Search</p>
            <input
              placeholder="Search Rescent Blogs"
              className="py-2 px-2 rounded-md border-2"
            />
            <p className="pt-5 text-xl font-semibold">RECENT BLOGS</p>
            <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-2">
              <div>
                <img src={img3} className="rounded-lg shadow-2xl lg:h-28" />
              </div>
              <div className="col-span-3">
                <p className="font-semibold">
                  find Out Why Primebuk is the best resource for you
                </p>
                <p>From cleaners to handymen to smart home installers.</p>
              </div>
            </div>
            <div className="py-2 ">
              <hr />
            </div>
          </div>
        </div>
      </section>
      {/* section end */}

      {/* section start */}

      <section className="px-10">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 col-span-2">
            <div>
              <img src={img2} className="rounded-2xl" />
            </div>
            <div className="col-span-1.5">
              <p className="text-xl font-semibold">
                find Out Why Primebuk is the best resource for you.
              </p>
              <p>
                From cleaners to handymen to smart home installers, Primebuk is
                always looking for service professionals who are experts in
                their trade and provide great service to their customers. The
                best home service professionals use Handy to find jobs with no
                lead fees and flexible scheduling.
              </p>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-2">
              <div>
                <img src={img3} className="rounded-lg shadow-2xl lg:h-28" />
              </div>
              <div className="col-span-3">
                <p className="font-semibold">
                  find Out Why Primebuk is the best resource for you
                </p>
                <p>From cleaners to handymen to smart home installers.</p>
              </div>
            </div>
            <div className="text-center bg-gray-100 p-4 mt-16 rounded-3xl">
              <p className="text-lg font-semibold">NEWSLETTER</p>
              <p className="text-gray-500">
                Register now to get latest updates on Promotions & Coupons.
              </p>
              <input
                type="email"
                placeholder="Enter Email"
                className="py-2 pl-2 rounded-md pe-10"
              />
              <br />
              <button
                type="button"
                className="bg-[#17A2B8] py-2 px-5 text-white mt-3 rounded-xl"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* section end */}
    </>
  );
};

export default Blog;
