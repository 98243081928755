import React from "react";
import { Carousel } from "react-bootstrap";

import rest1 from '../images/restaurant1.jpg';
import rest2 from '../images/restaurant2.png';
import rest3 from '../images/restaurant4.jpg';
import rest4 from '../images/restaurant5.png';
import restaurent3 from '../images/restaurant3.jpg';
import restaurent6 from '../images/restaurant6.jpg';
import restaurent7 from '../images/restaurant7.jpg';
import { NavLink } from "react-router-dom";

import rest5 from '../images/rest1.jpg';
import rest6 from '../images/restaurant1.jpg';
import rest7 from '../images/rest9.jpg';
import rest8 from '../images/rest5.jpg';
import rest9 from '../images/rest6.png';
import  rest10 from '../images/rest7.png';
import rest11 from '../images/rest10.jpg';
import rest12 from '../images/rest12.jpg';
import rest13 from '../images/rest13.jpg';
import rest14 from '../images/rest14.png';
import rest15 from '../images/rest15.png';
import rest16 from '../images/foodimg2.jpg';

const Restaurents = ()=>{
    return(
        <>


{/* <!-- Main body start --> */}
<div>
      
      <Carousel fade interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={restaurent3}
            alt="Slide 1"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={restaurent6}
            alt="Slide 2"/>

        </Carousel.Item>

                <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={restaurent7}
            alt="Slide 2"
          />

        </Carousel.Item>
        {/* Add more Carousel.Items as needed */}
      </Carousel>
    </div>






{/* <!-- Main body Start -->


<!-- Section2 start --> */}

<div class="container">
  <div class="row">
    <div class="col-md-3">
      <div class="sticky-sidebar services_section2_row1_col1">
       <p class="pt-2">Cuisines</p>


<ul>
    
<li><NavLink className='link2' to='/#northindian'>North Indian</NavLink></li>
<li><NavLink className='link2' to='#fastfood'>Fast Food</NavLink></li>
<li><NavLink className='link2' to='#chinese'>Chinese</NavLink></li>
<li><NavLink className='link2' to='#northindian'>Continental</NavLink></li>


<p class="pt-2">Tags</p>

<li><input type="checkbox"/><NavLink className='link2' to='#northindian'> Casual Dining</NavLink></li>
<li><input type="checkbox"/><NavLink className='link2' to='#northindian'> Cafe</NavLink></li>
<li><input type="checkbox"/><NavLink className='link2' to='#northindian'> Breakfast</NavLink></li>
<li><input type="checkbox"/><NavLink className='link2' to='#northindian'> Republic week 2023</NavLink></li>



</ul>
      </div>
    </div>

    <div class="col-md-9">

      <div class="content font-weight-bold">
      
<p class="pt-5" style={{fontSize:'23px'}} id="popular">Best Restaurents Near Me</p>

{/* card row1 */}
<div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
      <div className="bg-white rounded-lg shadow p-4 ">
        <img className="w-full" src={rest1} alt="Card Image 1" />
        <div className="mt-4 text-center">
          <h5 className="text-lg font-bold">Tanatan</h5>
          
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-4">
        <img className="w-full" src={rest2} alt="Card Image 2" />
        <div className="mt-4 text-center">
          <h5 className="text-lg font-bold">Melting point - Dayal Paradise</h5>
          
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-4">
        <img className="w-full" src={rest3} alt="Card Image 2" />
        <div className="mt-4 text-center">
          <h5 className="text-lg font-bold">Caprice</h5>
          
        </div>
      </div>
      {/* Add more cards as needed */}
    </div>
{/* card row1 */}

{/* card row2 */}


<div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-6">
      <div className="bg-white rounded-lg shadow p-4">
        <img className="w-full" src={rest5} alt="Card Image 1" />
        <div className="mt-4 text-center">
          <h5 className="text-lg font-bold">Altitude</h5>
          
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-4">
        <img className="w-full" src={rest6} alt="Card Image 2" />
        <div className="mt-4 text-center">
          <h5 className="text-lg font-bold">Delisia</h5>
          
        </div>
      </div>
      <div className="bg-white rounded-lg shadow p-4">
        <img className="w-full" src={rest7} alt="Card Image 2" />
        <div className="mt-4 text-center">
          <h5 className="text-lg font-bold">Nawabeen's Awadhi Restaurents</h5>
          
        </div>
      </div>
      {/* Add more cards as needed */}
    </div>
{/* card row1 */}




<div class="row pt-4 services_row1_ul">
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <ul>
       
          <li><NavLink className='link2' to='#'>Molecule</NavLink></li>
          <li><NavLink className='link2' to='#'>Boom Box</NavLink></li>
          <li><NavLink className='link2' to='#'>Destilry</NavLink></li>


        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>
      <li><NavLink className='link2' to='#'>Molecule</NavLink></li>
          <li><NavLink className='link2' to='#'>Boom Box</NavLink></li>
          <li><NavLink className='link2' to='#'>Destilry</NavLink></li>

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>
      <li><NavLink className='link2' to='#'>Molecule</NavLink></li>
          <li><NavLink className='link2' to='#'>Boom Box</NavLink></li>
          <li><NavLink className='link2' to='#'>Destilry</NavLink></li>

        </ul>
      </div>
    </div>



<p class="pt-5" style={{fontSize:'20px'}} id="northindian">North Indian</p>

<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
    <div className="bg-white shadow rounded-lg p-4">
        <img className="w-100" src={rest8}/>
        <div className="mt-4 text-center">
            <h5>Cafe 32</h5>
        </div>
    </div>
    <div className="bg-white shadow rounded-lg p-4">
        <img className="w-100" src={rest9}/>
        <div className="mt-4 text-center">
            <h5>The Pebbles Bistro & Bars</h5>
        </div>
    </div>
    <div className="bg-white shadow rounded-lg p-4">
        <img className="w-100" src={rest10}/>
        <div className="mt-4 text-center">
            <h5>The Ranch</h5>
        </div>
    </div>

</div>




<div class="row pt-4 services_row1_ul">
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <ul>

          <li><NavLink className='link2'>The Red Dot Family Restaurent</NavLink></li>
          <li><NavLink className='link2'>We Eaters</NavLink></li>
          <li><NavLink className='link2'>Sky View</NavLink></li>
          <li><NavLink className='link2'>Cosmos the Cafe</NavLink></li>
          <li><NavLink className='link2'>Cosmos the Cafe</NavLink></li>

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>
      <li><NavLink className='link2'>Well done Food</NavLink></li>
          <li><NavLink className='link2'>The Bachelors</NavLink></li>
          <li><NavLink className='link2'>Mr. Biryani</NavLink></li>
          <li><NavLink className='link2'>The Red Pepper</NavLink></li>
          <li><NavLink className='link2'>The Green Terrace</NavLink></li>

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>

      <li><NavLink className='link2'>Blues & Brewz</NavLink></li>
          <li><NavLink className='link2'>The Smoke House</NavLink></li>
          <li><NavLink className='link2'>Cheer Peer</NavLink></li>
          <li><NavLink className='link2'>Purple Foods</NavLink></li>
          <li><NavLink className='link2'>Mehk E Awadh</NavLink></li>

        </ul>
      </div>
    </div>


    <p class="pt-5" style={{fontSize:'20px'}} id="fastfood">Fast Food</p>
  
  
  <div className="grid gap-4 grid:cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3">
<div className="bg-white shadow p-4">
    <img className="w-full" src={rest11}/>
    <div className="mt-5 text-center">
        <h5>Al-baik</h5>
    </div>
</div>
<div className="bg-white shadow p-4">
    <img className="w-full" src={rest12}/>
    <div className="mt-5 text-center">
        <h5>Denbro by Mr. Brown</h5>
    </div>
</div>
<div className="bg-white shadow p-4">
    <img className="w-full" src={rest13}/>
    <div className="mt-5 text-center">
        <h5>Grill In</h5>
    </div>
</div>
  </div>
  

<div class="row pt-4 services_row1_ul">
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <ul>
            
          <li><NavLink className='link2' to='/'>The Fancave Cafe</NavLink></li>
          <li><NavLink className='link2' to='/'>Mr. Sandwich</NavLink></li>
         

          

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>
      <li><NavLink className='link2' to='/'>Ganden Bakery</NavLink></li>
          <li><NavLink className='link2' to='/'>Holy Monkey</NavLink></li>


          

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>
      <li><NavLink className='link2' to='/'>Kathi Castle</NavLink></li>
          <li><NavLink className='link2' to='/'>The Coffee Pallete</NavLink></li>

        </ul>
      </div>
    </div>


    <p class="pt-5" style={{fontSize:'20px'}} id="chinese">Chinese</p>
    
    <div className="grid grid:cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">

        <div className="shadow p-4">
            <img className="w-full" src={rest14}/>
            <div className="mt-4 text-center">
                <h5>Urban Terrace</h5>

            </div>
        </div>

        <div className="shadow p-4">
            <img className="w-full" src={rest15}/>
            <div className="mt-4 text-center">
                <h5>Chuck Bunglow</h5>

            </div>
        </div>

        <div className="shadow p-4">
            <img className="w-full" src={rest16}/>
            <div className="mt-4 text-center">
                <h5>Cage Cubeb</h5>

            </div>
        </div>
    </div>
    
    
<div class="row pt-4 services_row1_ul">
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <ul>
            
          <li><NavLink className='link2' to='/#'>Sharma's Restaurents</NavLink></li>
                    <li><NavLink className='link2' to='/#'>The Kingsland Cafe</NavLink></li>
                    <li><NavLink className='link2' to='/#'>Tashan Restro & Lounge</NavLink></li>


          

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>

      <li><NavLink className='link2' to='/#'>Various Restaurent</NavLink></li>
                    <li><NavLink className='link2' to='/#'>Pepperz</NavLink></li>
                    <li><NavLink className='link2' to='/#'>Hot Mix</NavLink></li>

        </ul>
      </div>
      <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <ul>

      <li><NavLink className='link2' to='/#'>The Urban Hermit</NavLink></li>
                    <li><NavLink className='link2' to='/#'>Pepperz</NavLink></li>
                    <li><NavLink className='link2' to='/#'>Vadu's</NavLink></li>


          

        </ul>
      </div>
    </div>

</div>


      </div>

    </div>

  </div>
{/* </div> */}


{/* <!-- Section2 end -->


<!-- Main body End -->

<!-- Main body end --> */}



        </>
    )
}

export default Restaurents;