import React from "react";
// import 'bootstrap/dist/css/bootstrap.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import homeimg from '../images/home.png';
import chef from '../images/cooking.png';
import shop from '../images/shopping-bag.png';
import restra from '../images/restaurant.png';
import supply from '../images/delivery-man.png';
import service from '../images/skill.png';
import taxi from '../images/cab.png';
import job1 from '../images/job-offer.png';
import gettheapp from '../images/gettheapp.png';
import android from '../images/androiddownload.png';
import ios from '../images/iosdownload.png';
import homeshop2 from '../images/homeshop2.png';
import banner1 from '../images/taxibook1.jpg';
import banner2 from '../images/taxibook2.png';
import becomeAPro from '../images/become a pro.jpg';
import findjob1 from '../images/job-seeker-bg.jpg';
import { NavLink } from "react-router-dom";


 const Home = ()=>{
    return(
        <>
        {/* <!-- section 8 modules start --> */}

  

{/* Cards section1 start */}
{/* card row 1 */}



    <div className="container mx-auto p-4">
    <div className="firstheading" ><p>The easy, reliable way to take care of your home. </p></div>
      <div className="flex flex-wrap -mx-4 text-center">
        <div className="w-1/2 md:w-1/4 p-4">
        <NavLink className='main_module_link' to='/'>
        <div className="bg-white rounded-lg shadow-md p-4 homeshop">
            
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={homeimg}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Home Shoppe</h2>
      </div>
    </div></NavLink>
        </div>
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/chef'>
        <div className="bg-white rounded-lg shadow-md p-4 homechef">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={chef}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Home Chef</h2>

      </div>
    </div></NavLink>
        </div>
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/shopping'>
        <div className="bg-white rounded-lg shadow-md p-4 shopping">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={shop}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Shopping</h2>

      </div>
    </div></NavLink>
        </div>
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/restaurents'>
        <div className="bg-white rounded-lg shadow-md p-4 restra">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={restra}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Restaurents</h2>

      </div>
    </div></NavLink>
        </div>
      </div>
    </div>
{/* card row 1 end */}


{/* Card row 2 start */}
<div className="container mx-auto p-4">
      <div className="flex flex-wrap -mx-4 text-center">
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/supplies'>
        <div className="bg-white rounded-lg shadow-md p-4 supl">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={supply}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Supplies</h2>

      </div>
    </div>
    </NavLink>
        </div>
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/services'>
        <div className="bg-white rounded-lg shadow-md p-4 serve">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={service}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Services</h2>

      </div>
    </div></NavLink>
        </div>
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/taxi'>
        <div className="bg-white rounded-lg shadow-md p-4 taxi">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={taxi}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Taxi</h2>

      </div>
    </div></NavLink>
        </div>
        <div className="w-1/2 md:w-1/4 p-4">
            <NavLink className='main_module_link' to='/Jobs'>
        <div className="bg-white rounded-lg shadow-md p-4 jobs">
      <img
        className="w-full h-20 object-contain rounded-t-lg"
        src={job1}
        alt="Card Image"
      />
      <div className="p-4">
        <h2 className="text-sm lg:text-xl font-semibold">Jobs</h2>

      </div>
    </div></NavLink>
        </div>
      </div>
    </div>
{/* Card row 2 end */}
{/* Cards section end */}
{/* Section4 start */}

<div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
<div>
     
     <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
       <div>
         <img className="w-full h-full" src={banner1} alt="Slide 1" />
       
       </div>
       <div>
         <img className="w-full h-full" src={banner2} alt="Slide 2" />
         
       </div>
       {/* Add more slides as needed */}
     </Carousel>
   </div>
   <div>
     
     <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
       <div>
         <img className="w-full h-full" src={banner1} alt="Slide 1" />
       
       </div>
       <div>
         <img className="w-full h-full" src={banner2} alt="Slide 2" />
         
       </div>
       {/* Add more slides as needed */}
     </Carousel>
   </div>
   <div>
     
     <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
       <div>
         <img className="w-full h-full" src={banner1} alt="Slide 1" />
       
       </div>
       <div>
         <img className="w-full h-full" src={banner2} alt="Slide 2" />
         
       </div>
       {/* Add more slides as needed */}
     </Carousel>
   </div>
   <div>
     
     <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
       <div>
         <img className="w-full h-full" src={banner1} alt="Slide 1" />
       
       </div>
       <div>
         <img className="w-full h-full" src={banner2} alt="Slide 2" />
         
       </div>
       {/* Add more slides as needed */}
     </Carousel>
   </div>

    </div> 


{/* Section4 end */}

{/* section2 start */}




<div className="container mx-auto py-24">
<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
    <div>
<h2 className="text-[35px] font-bold pb-5">Get the Primebuk App</h2>
    <p className="text-xl">Book and manage appointments, message your pro, view pro profiles and ratings, see real-time location of your pro and so much more.</p>
<div className="flex gap-2  pt-10">
    <div>
        <NavLink to='/#'><img className="w-full h-16 object-contain" src={android}/>
        </NavLink>
        </div>
    <div>
        <NavLink to='/#'><img className="w-full h-16 object-contain" src={ios}/></NavLink>
        </div>
</div>



</div>
<div className="flex justify-center"><img src={gettheapp}/></div>
</div>
</div>
{/* section2 end */}

{/* Section3 start */}
<section className="pb-24">
<div style={{backgroundImage:`url(${homeshop2})`,height:'500px',width:'100%', backgroundSize:'cover', backgroundPosition:'center'}}>
<div className="container mx-auto py-24">
<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
    <div>
<h2 className="text-[35px] font-bold pb-5">Shop for your Home with Primebuk</h2>
    <p className="text-xl">Shop furniture, electronics, appliances, and more. Everything comes with expert installation by primebuk.</p>

    <button
      className="bg-cyan-500 hover:bg-cyan-900 text-white font-bold mt-5 py-4 px-4 rounded">Shop All Home Products</button>


</div>

</div>
</div>
</div>
</section>
{/* Section3 end */}


{/* section5 start */}

<div className="container mx-auto py-16">
<div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
    <div>
<h2 className="text-[35px] font-bold pb-5 pt-20">Are You a Five Star Pro?</h2>
    <p className="text-xl text-justify">From cleaners to handymen to smart home installers, Primebuk is always looking for service professionals who are experts in their trade and provide great service to their customers. The best home service professionals use Handy to find jobs with no lead fees and flexible scheduling.</p>

    <button
      className="bg-cyan-500 hover:bg-cyan-900 text-white font-bold mt-5 py-4 px-4 rounded">Become a Pro</button>


</div>
<div><img src={becomeAPro}/></div>
</div>
</div>
{/* section5 end */}

{/* section 6 start*/}

<section className="pb-10">
<div className="mx-20" style={{boxShadow:'1px 1px 9px 1px'}}>
    <p className="text-2xl font-bold p-2">Popular Searches</p>
    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4  pb-10 px-5 gap-8 justify-items-center ">
    <div>
<ul>
    <li>
        <NavLink className='link2'>cleaning services Lucknow</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Delhi</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Mumbai</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Kokata</NavLink>
    </li>
</ul>
</div>
<div>
<ul>
    <li>
        <NavLink className='link2'>cleaning services Lucknow</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Delhi</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Mumbai</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Kokata</NavLink>
    </li>
</ul>
</div>
<div>
<ul>
    <li>
        <NavLink className='link2'>cleaning services Lucknow</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Delhi</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Mumbai</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Kokata</NavLink>
    </li>
</ul>
</div>
<div>
<ul>
    <li>
        <NavLink className='link2'>cleaning services Lucknow</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Delhi</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Mumbai</NavLink>
    </li>
    <li>
        <NavLink className='link2'>cleaning services Kokata</NavLink>
    </li>
</ul>
</div>
</div>
</div>
</section>

{/* section 6 end */}

        </>
    )
}
export default Home;