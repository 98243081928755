import React from "react";
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import shoppingbanner2 from '../images/shoppingbanner2.jpg';
import shoppingbanner3 from '../images/shoppingbanner3.jpg';
import shoppingbanner4 from '../images/shoppingbanner5.png';
import shoes from '../images/shoes.jpg';
import watch from '../images/watch2.jpg';
import sleeper from '../images/sleeper.png';
import kurta from '../images/kurtas.jpg';
import tshirt from '../images/tshirt.jpg';
import pcovers from '../images/pillowcovers.jpg';
import mattress from '../images/mattressprotect.png';
import towel from '../images/bathtowels.jpg';
import tablecovers from '../images/tablecover.png';
import beddingset from '../images/beddingsets.png';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
 const Shopping = ()=>{
    return(
        <>
      {/* <!-- Main body start -->
<!-- seciton1 start --> */}

<div>
      
      <Carousel fade interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={shoppingbanner2}
            alt="Slide 1"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={shoppingbanner3}
            alt="Slide 2"/>

        </Carousel.Item>

                <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={shoppingbanner4}
            alt="Slide 2"
          />

        </Carousel.Item>
        {/* Add more Carousel.Items as needed */}
      </Carousel>
    </div>





{/* <!-- section1 end -->





{/* section4 start */}

<section>
    
    <div className="grid grid-cols-4 bg-green-600 p-4 font-bold text-white justify-items-center">
        
        <h3><FiberManualRecordIcon className="text-yellow-300"/> 5L + Products </h3>
        <h3><FiberManualRecordIcon className="text-yellow-300"/>Latest Styles</h3>
        <h3><FiberManualRecordIcon className="text-yellow-300"/>Fast Devlivery</h3>
        <h3><FiberManualRecordIcon className="text-yellow-300"/> Lowest Prices</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 py-5 text-center">
            <div className="pt-5">
                <p className="font-bold text-3xl">Top Deals On
                 Fashion</p>
                 <button class="rounded-lg text-white p-2 bg-blue-500">View All</button>
               </div>
               <div>
               <img src={shoes} alt="" className="w-full h-48 object-contain" />
               <p className="font-bold">Sparx, Bata & More</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 149</p>
               <p className="text-gray-400">Trending Now!</p>
               </div>
               
               <div>
               <img src={watch} alt="" className="w-full h-48 object-contain"/>
               <p className="font-bold">Fastrack, Timex, Sonata</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 499</p>
               <p className="text-gray-400">Rush to Grab The Best offer!</p>
               </div>

               <div>
               <img src={sleeper} className="w-full h-48 object-contain"  alt=""/>
               <p className="font-bold">Summer Special !!</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 399</p>
               <p className="text-gray-400">Sandals & Sliders</p>
               </div>


               <div>
               <img src={kurta} className="w-full h-48 object-contain"  alt=""/>
               <p className="font-bold">Supper Offers on Kurtas & Sets</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 599</p>
               <p className="text-gray-400">Bijli Deals!</p>
               </div>

               <div>
               <img src={tshirt} className="w-full h-48 object-contain"  alt=""/>
               <p className="font-bold">Flying Machine</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 499</p>
               <p className="text-gray-400">Trending Now!</p>
               </div>
        
        </div>


        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 py-3 text-center">
            <div className="pt-5">
                <p className="font-bold text-3xl">Home Furnishing</p>
                 <button class="rounded-lg text-white p-2 bg-blue-500">View All</button>
               </div>
               <div>
               <img src={pcovers} alt="" className="w-full h-48 object-contain" />
               <p className="font-bold">Sparx, Bata & More</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 149</p>
               <p className="text-gray-400">Trending Now!</p>
               </div>
               
               <div>
               <img src={mattress} alt="" className="w-full h-48 object-contain"/>
               <p className="font-bold">Fastrack, Timex, Sonata</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 499</p>
               <p className="text-gray-400">Rush to Grab The Best offer!</p>
               </div>

               <div>
               <img src={towel} className="w-full h-48 object-contain"  alt=""/>
               <p className="font-bold">Summer Special !!</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 399</p>
               <p className="text-gray-400">Sandals & Sliders</p>
               </div>


               <div>
               <img src={tablecovers} className="w-full h-48 object-contain"  alt=""/>
               <p className="font-bold">Supper Offers on Kurtas & Sets</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 599</p>
               <p className="text-gray-400">Bijli Deals!</p>
               </div>

               <div>
               <img src={beddingset} className="w-full h-48 object-contain"  alt=""/>
               <p className="font-bold">Flying Machine</p>
               <p className="text-green-600"><CurrencyRupeeIcon fontSize="small"/>From 499</p>
               <p className="text-gray-400">Trending Now!</p>
               </div>
        
        </div>

</section>



{/* section4 end */}

{/* <!-- Main body end --> */}



        </>
    )
}

export default Shopping;