import React from "react";
import { useState } from "react";
import img2 from '../images/cityview.jpg';
const Hooks = ()=>{

const [color, setcolor] = useState(0);
const arr = [1,2,3,4,5];
const handlearr = arr.map((i)=>{
   return(
    <img src={img2}/>
   )

});
    return (
        <>
<h1>Favourite color is - {color}</h1>
<button className="text-white bg-black rounded-xl p-2" type="button" onClick={()=>setcolor(2)}>Increase</button>

<h1 className="flex gap-3">{handlearr}</h1>



        </>
    )
}
export default Hooks;