import React from "react";
import cityview3 from '../images/cityview3.jpg';
import taxibenifits from '../images/taxibenifits.jpg';
import instanttaxi from '../images/instanttaxi.jpg';
import auto from '../images/auto.jpg';
import motorbike from '../images/motorbike.png';
import rentalcar from '../images/rental-car.png';
import car from '../images/car.png';
import taxibooking from '../images/texibooking.jpg';

const Taxi = ()=>{
    return(
        <>
    {/* <!-- Main Body Start -->
<!-- Section3 start --> */}
<section className="section3_bg_img " style={{backgroundImage:`url(${cityview3})`,height:'500px',width:'100%', backgroundSize:'cover', backgroundPosition:'center'}}>
    <div className="container">
    <div className="row">
     
      <div className="col-12">
        <div className="taxi_cardinner" >
          <p className="p1">Unleashing the potential of Primebuk, revolutionizing it for the business realm</p>
    <p className="p2">A platform for managing global rides and meals, and local deliveries, for companies of any size.</p>
    <div className="text-center">
    <a href="" className="btn btn-dark getstarted">Get Started</a>
    </div>

      </div>
      </div>
     
    </div>
  </div>  

    </section>
{/* <!-- Section3 end -->
<!-- Section1 start --> */}
<section>
    <div className="container pt-5">
    <form action="">
        <div className="row">
            
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <input type="text" placeholder="Enter Pickup Location" className="form-control"/>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                <input type="text" placeholder="Enter destination" className="form-control"/>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <input type="submit" className="btn btn-info btn-block" value="Let's Go!"/>
            </div>
            
        </div>
        </form>

        <div className="row pt-4">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 taxi_section1_row2_col1">
                <p className="p1">Get An Instant Ride Whenever or Wherever You Want.</p>
                <p className="p2">Request a Ride Now!</p>
                <a href="" className="btn btn-info ">See Prices</a>
            </div>
            <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <img src={instanttaxi}alt="" width="100%" height="500px"/>
            </div>
        </div>
    </div>
</section>
{/* <!-- Section1 end -->

<!-- Section4 start --> */}

<section>
    <div className="container pt-5 section4_container">
        <p className="p1">
            Ride with Primebuk
        </p>
        <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
               <div className="row">
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img src={auto} alt="" width="50%"/>
                </div>
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
<p className="p2">Primebuk Auto</p>
<p className="p3">Get affordable Uber Auto rides with no haggling. Request Uber Auto and ride comfortably around your city.</p>
                </div>
               </div> 
            </div>
            <div className="col-1 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
               <div className="row">
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img src={motorbike} alt="" width="50%"/>
                </div>
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
<p className="p2">Primebuk Bike</p>
<p className="p3">Get affordable bike rides at your doorstep. Skip the crowd and zip through traffic with Primebuk Bike.</p>
                </div>
               </div> 
            </div>
           
        </div>


        <div className="row pt-5">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
               <div className="row">
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img src={rentalcar} alt="" width="50%"/>
                </div>
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
<p className="p2">Primebuk Rentals</p>
<p className="p3">Book Rentals to save time with one car and driver for your multi-stop trips.</p>
                </div>
               </div> 
            </div>
            <div className="col-1 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
               <div className="row">
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <img src={car} alt="" width="50%"/>
                </div>
                <div className="col-4 col-sm-6 col-md-6 col-lg-6 col-xl-6">
<p className="p2">Primebuk Intercity</p>
<p className="p3">Book Intercity to head outstation anytime in convenient and affordable cars.
</p>
                </div>
               </div> 
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"></div>
        </div>


    </div>
</section>

{/* <!-- Section4 end -->



<!-- Section2 start --> */}
<section>
    <div className="container  taxi_section2_container ">
        <p className="p1 text-center">Benifits of using Primebuk app?</p>
        <div classNameName="road">
            <div classNameName="car">
          
            </div>
            <img src={taxibenifits} alt="" width="40%"/>
        </div>

        <div className="row pt-5 text-center">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 taxi_section2_row1_col2">
            <i className="fa-solid fa-clock"></i>
            <p className="p2">Rides on demand</p>
            <p className="p3">Request a ride at any time and on any day of the year.</p>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 taxi_section2_row1_col2">
            <i className="fa-solid fa-money-bill-transfer"></i>
            <p className="p2">Budget-friendly options</p>
            <p className="p3">Compare prices on every kind of ride, from daily commutes to special evenings out.</p>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 taxi_section2_row1_col2">
            <i className="fa-solid fa-map-location-dot"></i>
            <p className="p2">An easy way to get around</p>
            <p className="p3">Tap and let your driver take you where you want to go.</p>
            </div>

        </div>
    </div>
</section>

{/* <!-- Section2 end -->

<!-- section5 start --> */}
<section>
    <div className="container taxi_section5_container">

<div className="row">
    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 ">
        <img src={taxibooking} alt="" width="100%"/>
    </div>
    <div className="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1"></div>
    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 section5_row1_col2">
        <p className="p1">Ways people move around the world</p>
        <p className="p2">The Primebuk app gives you the power to get where you want to go with access to different types of rides across more than 10,000 cities.</p>
        <a href="" className="btn btn-info viewride">View ride options</a>
    </div>
</div>
    </div>
</section>

{/* <!-- section5 end -->


<!-- Main Body End --> */}
        </>
    )
}
export default Taxi;