import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import primebuklogo from '../images/Primebuk-Logo-1.png';

import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Card,
} from "@material-tailwind/react";

const Header = ()=>{
    const [navOpen, navClose] = useState('navOpen');


    const [openNav, setOpenNav] = React.useState(false);
 
    React.useEffect(() => {
      window.addEventListener(
        "resize",
        () => window.innerWidth >= 960 && setOpenNav(false)
      );
    }, []);
   
    const navList = (
      <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 ">
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='/'>Home</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='shopping'>Shopping</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='restaurents'>Restaurents</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='supplies'>Supplies</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='services'>Services</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='taxi'>Taxi</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='jobs'>Jobs</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='career'>Career</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='blog'>Blog</NavLink>
        </Typography>
        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='news'>News</NavLink>
        </Typography>

        <Typography
          as="li"
          variant="small"
          color="blue-gray"
          className="p-1 font-normal"
        >
<NavLink className='link1' to='support'>Support</NavLink>
        </Typography>
      </ul>
    );
    return(

    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4 pt-2  bg-[#17A2B8]">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Typography
            as="a"
            href="#"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
           <img src={primebuklogo} width='250px'/>
          </Typography>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">{navList}</div>
            {/* <Button
              variant="gradient"
              size="sm"
              className="hidden lg:inline-block"
            >
              <span>Buy Now</span>
            </Button> */}
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>
          {navList}
          {/* <Button variant="gradient" size="sm" fullWidth className="mb-2">
            <span>Buy Now</span>
          </Button> */}
        </MobileNav>
      </Navbar>
     
    </>
  );
}









// <nav className="py-3 bg-[#17A2B8]">
// <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//     <div className="flex items-center justify-between h-16">
//         <div className="text-white font-bold text-xl shrink-0">
//             <img src={primebuklogo} width='250px'/>
//         </div>
//     <div className=" space-x-4 section1_nav sm:visible md:invisible pt-2">
//     <NavLink className='link1' to='/'>Home</NavLink>
//         <NavLink className='link1' to='shopping'>Shopping</NavLink>
//         <NavLink className='link1' to='restaurents'>Restaurents</NavLink>
//         <NavLink className='link1' to='supplies'>Supplies</NavLink>
//         <NavLink className='link1' to='services'>Services</NavLink>
//         <NavLink className='link1' to='taxi'>Taxi</NavLink>
//         <NavLink className='link1' to='jobs'>Jobs</NavLink>
//     </div>
//     <button type="button" onClick={()=>{alert('ok')}}></button>
//     </div>
// </div>
// </nav>

//         </>
//     )
// }

export default Header;