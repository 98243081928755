import React from "react";
import { FaBeer } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import homeshop from "../images/homeshop.png";
import service_img1 from "../images/images/Home Furniture Installation Service.jpg";
import service_img2 from "../images/images/Commercial Pest Control.jpg";
import service_img3 from "../images/images/fabricationmain.jpg";
import service_img4 from "../images/images/Interior Designermain.png";
import service_img5 from "../images/images/Kitchen Cleaning Servicesmain.jpg";
import service_img6 from "../images/images/Pipe Lines And Pumps.jpg";
import service_img7 from "../images/images/Deep Floor Cleaning Service.jpg";
import service_img8 from "../images/images/Kitchen Cleaning Servicesmain.jpg";
import service_img9 from "../images/images/Cleaning Services From Offices.jpg";
import service_img10 from "../images/images/laptoprepairmain.jpg";
import service_img11 from "../images/images/LED TV Installation And Repair.jpg";
import service_img12 from "../images/images/LCD TV Installation And Repair.jpg";
import service_img13 from "../images/images/FURNITURE1.jpg";
import service_img14 from "../images/images/Furniture Repair.png";
import service_img15 from "../images/images/Full Home Health Check.jpg";
import service_img16 from "../images/images/banner3.jpg";
import service_img17 from "../images/images/aboutusbanner3.jpg";
import service_img18 from "../images/images/Western Toilet.jpg";
import service_img19 from "../images/images/plumberbanner.jpg";
import service_img20 from "../images/images/PLUMBERING.jpg";
import service_img21 from "../images/images/PAINTER.jpg";
import service_img22 from "../images/images/Painting And Polishing Work.png";
import service_img23 from "../images/images/PAINTINGCONTRACTORS.jpg";
import service_img24 from "../images/images/mimomain.jpg";
import service_img25 from "../images/images/MOVEINMOVEOUT.jpg";
import service_img26 from "../images/images/loading-unloading.jpg";
import service_img27 from "../images/images/Smart Home Solutions.jpg";
import service_img28 from "../images/images/Wooden Partition.png";
import service_img29 from "../images/images/Office False Ceiling Service.jpg";
const Services = () => {
  return (
    <>
      {/* 
<!-- Main body Start -->
<!-- section1 start --> */}
      <section
        className="services_section1_bgimg"
        style={{
          backgroundImage: `url(${homeshop})`,
          height: "500px",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8 text-center">
              <div className="cardinner">
                <p className="p1">Choose a service to get started.</p>
                <input
                  type="text"
                  className="form-control search_inputs"
                  placeholder="Search for a service (e.g. 'cleaning')"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* // <!-- section1 end --> */}

      {/* // <!-- Section2 start --> */}

      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="sticky-sidebar services_section2_row1_col1">
              <p className="pt-2">All Categories</p>

              <ul>
                <li>
                  {" "}
                  <NavLink className="link2" to="#popular">
                    Popular
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="link2" to="#cleaning">
                    Cleaning
                  </NavLink>
                </li>
                <li>
                  <NavLink className="link2" to="#tv">
                    TV & Electronics
                  </NavLink>
                </li>
                <li>
                  <NavLink className="link2" to="#assembly">
                    Assembly
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="link2" to="#generalhandyman">
                    General Handyman
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="link2" to="#plumbing">
                    Plumbing
                  </NavLink>
                </li>
                <li>
                  <NavLink className="link2" to="#popular">
                    Electrical
                  </NavLink>
                </li>
                <li>
                  <NavLink className="link2" to="#painting">
                    Painting
                  </NavLink>
                </li>
                <li>
                  <NavLink className="link2" to="#moving">
                    Moving
                  </NavLink>
                </li>
                <li>
                  {" "}
                  <NavLink className="link2" to="#smarthome">
                    Smart Home
                  </NavLink>
                </li>
                <li>
                  <NavLink className="link2" to="#smarthome">
                    Window Treatments
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-9">
            <div className="content font-weight-bold">
              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Popular{" "}
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img1}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">
                      Home Furniture Installation
                    </h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img2}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">
                      Commercial Pest Control
                    </h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img3}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Fabrication</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-6">
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img4}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Interior Designing</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img5}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">kitchen Cleaning</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img6}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Plumbing</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Interior Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Furniture Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Office Cleaning
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Moving Help
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Handyman Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        TV Mounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Home Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Hanging Pictures & Shelves
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Pick-up/Delivery
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Cleaning{" "}
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img7}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Floor Cleaning</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img8}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Kitchen Cleaning</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img9}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Office Cleaning</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Affordable Cleaning Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Bedroom Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Housekeeping
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Move Out Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Home Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Deep Cleaning Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Local Cleaning Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Commercial Sanitization
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Affordable Maids
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Cleaning Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Maid Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Room Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Office Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Kitchen Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Local Cleaning Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Same Day Cleaning
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Apartment Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Hemlock Hardware: Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Move In Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Samara
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Vacation Rental Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Living Room Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Local Cleaning Service
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Home Sanitization
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                TV & Electronics
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img10}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">TV Repairing</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img11}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">TV Installation</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img12}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">LCD TV Installation</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Hiding TV Wires
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        TV Wall Mount Installation
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Soundbar Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        TV Mounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        TV Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Home Theater AV Setup
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Furniture Assemby
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img13}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Furniture Assembly</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img14}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Chair Assembly</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img1}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">
                      Home Furniture Assembly
                    </h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Desk Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Grill Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Bed Assembly
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Dresser Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Outdoor Furniture Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Office Furniture Assembly
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Exercise Equipment
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Furniture Assembly
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                General Handyman
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img15}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Handyman Services</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img16}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Drilling</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img17}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Carpentry & Repairs</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Air Conditioner Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Furniture Assembly
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Home Repair Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Locks Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Hanging Pictures & Shelves
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Air Conditioner Uninstall
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Handy Helper
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Interior Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        TV Mounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Carpentry & Repairs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Hemlock Hardware: Handyman
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Knobs Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Locks Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Handyman Service
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Plumbing
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img20}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Plumbing Services</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img18}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Western Toilet Repair</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img6}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Pipe Lines & Pumps</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Drain Repair
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Sink Replacement
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Unclog Toilet
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Plumbing Service
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Faucet Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Toilet Repair
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Faucet Replacement
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Faucet Repair
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Toilet Replacement
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Toilet Trouble
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Painting
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img21}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Interior Painting</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img22}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">
                      Painting & Polishing Work
                    </h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img23}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Contaractors</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Baseboard Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Doorframe Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Interior Painting
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Crown Molding Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        House Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Bedroom Painting
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Door Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Wall Painting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Accent Wall Painting
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Moving
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img24}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Office Moving</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img25}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Home Moving</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img26}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Loading unloading</h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Move Out Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Hanging Pictures & Shelves
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        TV Mounting
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Moving Help
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Light Fixtures
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Move In Cleaning
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Wall Painting
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <p className="pt-5" style={{ fontSize: "23px" }} id="popular">
                Smart Home
              </p>

              {/* card row1 */}
              <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 sm:text-center">
                <div className="bg-white rounded-lg shadow p-4 ">
                  <img
                    className="w-full"
                    src={service_img27}
                    alt="Card Image 1"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Smart Home Solutions</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img28}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">Home Service</h5>
                  </div>
                </div>
                <div className="bg-white rounded-lg shadow p-4">
                  <img
                    className="w-full"
                    src={service_img29}
                    alt="Card Image 2"
                  />
                  <div className="mt-4 text-center">
                    <h5 className="text-lg font-bold">
                      3D Architectural Services
                    </h5>
                  </div>
                </div>
                {/* Add more cards as needed */}
              </div>
              {/* card row1 */}

              {/* card row2 */}

              <div class="row pt-4 services_row1_ul font-bold">
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Exchange Smart Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Exchange Video Doorbell
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Sam's Club Smart Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Smart Thermostat
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Walmart Smart Home Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Smart Device Installation
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Exchange Smart Lock Istal
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Lowe's Smart Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Sam's Club Smart Home - Complex
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Target Smart Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Wi-Fi Router Setup
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <ul>
                    <li>
                      <NavLink className="link2" to="#">
                        Exchange Smart Thermostat
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Partner Smart Home Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Smart Security Cam Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Video Doorbell Installation
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="link2" to="#">
                        Smart Home Hub Setup
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Section2 end --> */}

      {/* 
<!-- Main body End --> */}
    </>
  );
};

export default Services;
