import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { Typography,Rating } from "@mui/material";
const Rough4MainPage = ()=>{
    const{id} = useParams();
    const [singleData, setSingleData] = useState([]);
    const [ratingValue, setRatingValue ] = useState("");
    const singleDataListing = ()=>{
        axios.get(`https://dummyjson.com/products/${id}`)
        .then(function(res){
            setSingleData(res.data);
            console.log(res.data);
        })
    }

    useEffect(()=>{
        singleDataListing();
    },[])
    return(
        <>
     <div className="grid grid-cols-2 p-2 gap-5">
        <div >
            <img src={singleData?.thumbnail} className="px-2 w-full" />
        <div className="flex pt-2 ">
        {
            singleData?.images?.map((i)=>{
                return(
                    <>
                  
                    <img src={i} className="w-36 h-32 px-2"/>

                
                </>
                )
            })
        }

</div>

</div>
<div>
    <p className="text-2xl font-bold">{singleData.title}</p>
    <p className="semibold">{singleData.brand}</p>
    <p className="text-green-600 font-bold">Extra {singleData.discountPercentage}% Off</p>
    <div className="flex">
    <CurrencyRupeeIcon fontSize='small'/> <p className="text-2xl">{singleData.price}</p>
    </div>
    <Typography component="legend">Rating</Typography>
<Rating
  name="simple-controlled"
  value={singleData.rating}
  onChange={(event, newValue) => {
    setRatingValue(newValue);
  }}
/>

    <p className="mb-10">{singleData.description}</p>
    <div className="pt-5 flex gap-3 ">
<button className="shadow-lg text-white bg-orange-600 py-2 px-5">
<div className="flex justify-between"><ShoppingCartIcon/>ADD TO CART</div>
              </button>
              <button className="shadow-lg text-white bg-yellow-500 py-2 px-5">
               <div><FlashOnIcon/>BUY NOW</div> 
              </button>
              </div>
</div>
        </div>
  
        </>
    )
}
export default Rough4MainPage;