import React, { useState } from "react";
import { FaOpera, FaEnvelopeOpenText } from "react-icons/fa";
import { BrowserRouter, NavLink } from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import becomepro from '../images/become a pro.jpg';
import jobseeker from '../images/job-seeker-bg.jpg';

const Career = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  return (
    <>

      <div className="pt-24">
        <p className="text-5xl text-center text-[#17A2B8] font-bold pb-10">Job Openings</p>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 mx-5 lg:mx-20 pt-10">
          <div>
            <p className="text-3xl font-bold">We Are With You In Your Success</p>
            <p>Welcome to Primebuk It Takes a new kind of Intelligence to nevigate a new world.</p>
            <div className="flex gap-2">
              <FaOpera className="text-3xl" /> <p className="text-lg"> Website: <NavLink>https://primebuk.com/</NavLink></p>
            </div>
            <div className="flex gap-2">
              <FaEnvelopeOpenText className="text-3xl" /> <p className="text-lg"> Send Your Resume At: <NavLink>hr@primebuk.com/</NavLink></p>
            </div>

            <div className="mt-5 border border-slate-500">
              <p className="text-4xl font-bold text-white bg-[#17A2B8] p-2 text-center">Apply For Jobs</p>
              <form>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mx-4">
                  <input type="text" placeholder="First Name" className="p-2 border" />
                  <input type="text" placeholder="Last Name" className="p-2 border" />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mx-4 pt-3">
                  <input type="email" placeholder="Email" className="p-2 border" />
                  <input type="number" placeholder="Mobile" className="p-2 border" />
                </div>
                <div className="mx-4  pt-3">
                  <input type="text" placeholder="Message" className="p-2 border w-full" />
                </div>
                <div className="mx-4 pt-3">
                  <input type="text" placeholder="Experience" className="p-2 border w-full" />
                </div>
                <div className="mx-4  pt-3 pb-3">
                  <input type="file" className="p-2 border w-full" />
                </div>
                <div className="text-center pb-3">
                  <button className="bg-[#17A2B8] text-white py-3 px-6 rounded-3xl shadow-lg shadow-red-500">Send Your Request</button>
                </div>
              </form>
            </div>

          </div>
          <div>
            <p className="text-3xl font-bold sm:pt-5 ">Here Our Company Jobs Positions</p>
            <p>Open for job postions in Primebuk. It Takes a new kind of Intelligence to nevigate a new world.</p>

            {/* Dropdown 1 */}
            <div>
              <button onClick={() => toggleDropdown(1)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Plumber</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 1 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 1 ends */}

            {/* Dropdown 2 */}
            <div>
              <button onClick={() => toggleDropdown(2)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>House Cleaner</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 2 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 2 ends */}

            {/* Dropdown 3 start */}
            <div>
              <button onClick={() => toggleDropdown(3)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>IT Support Manager</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 3 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 3 ends */}


            {/* Dropdown 4 start */}
            <div>
              <button onClick={() => toggleDropdown(4)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Painter</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 4 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 4 ends */}




                        {/* Dropdown 5 start */}
                        <div>
              <button onClick={() => toggleDropdown(5)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Pest Control</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 5 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 5 ends */}



                        {/* Dropdown 3 start */}
                        <div>
              <button onClick={() => toggleDropdown(6)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Carpenter</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 6 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 6 ends */}




                        {/* Dropdown 7 start */}
                        <div>
              <button onClick={() => toggleDropdown(7)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Electrician</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 7 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 7 ends */}




                        {/* Dropdown 8 start */}
                        <div>
              <button onClick={() => toggleDropdown(8)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Graphic Designer</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 8 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 8 ends */}




                        {/* Dropdown 9 start */}
                        <div>
              <button onClick={() => toggleDropdown(3)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Interior Designers</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 9 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 9 ends */}




                        {/* Dropdown 10 start */}
                        <div>
              <button onClick={() => toggleDropdown(10)} className="border w-full flex justify-between px-8 py-2 text-xl font-bold">
                <p>Architects</p>
                <p><ArrowDropDownIcon /></p>
              </button>
              {openDropdown === 10 && (
                <div>
                  {/* Dropdown content */}
                  <div className="flex justify-center">
                    <img src={jobseeker} className="h-96 w-full py-4" alt="Job Seeker" />
                  </div>
                  <div>
                    <p>1. Years Of Experience</p>
                    <ol className="font-bold">
                      <li>2-4 Years Experienced</li>
                    </ol>
                    <p>2. Job Date:</p>
                    <ol className="font-bold">
                      <li>2023-06-16</li>
                    </ol>
                    <p>3. Eligibility:</p>
                    <ol className="font-bold text-2xl">
                      <li>*Job Description:-</li>
                    </ol>
                  </div>
                </div>
              )}
            </div>
            {/* Dropdown 10 ends */}

          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
