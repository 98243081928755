import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Rough4 = ()=>{
    
    const [dummyData, setDummyData] = useState([]);
const navigate = useNavigate();
    const dummyDataList = ()=>{
        axios.get('https://dummyjson.com/products')
        .then(function(res){
            setDummyData(res?.data?.products);
        })
    }

    useEffect(()=>{
        dummyDataList();
    },[])
    return(
        <>
        <div className="grid grid-cols-4 p-2 gap-2 justify-items-center">
        {
            dummyData.map((i)=>{
                return(
                    <div className="shadow-xl"  onClick={()=>navigate(`/rough4mainpage/${i.id}`)}>
                    <img src={i.thumbnail} className="h-52 w-96"/>
                    <p>{i.title}</p> 
                    <p>{i.brand}</p>

                    </div>

                )
            })
        }
        </div>
        </>
    )
}

export default Rough4;