import React from 'react';
import './App.css';
import './style.css';
import { ReactDOM } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Shopping from './components/Shopping';
import Restaurents from './components/Restaurents';
import Services from './components/Services';
import Supplies from './components/Supplies';
import Taxi from './components/Taxi';
import Jobs from './components/Jobs';
import Career from './components/Career';
import Header from './components/Header';
import Footer from './components/Footer';
import Blog from './components/Blog';
import News from './components/News';
import Support from './components/Support';
import Hooks from './Hooks';
import Getapi from './components/dummyapi';
import Rough2 from './components/rough2';
import Rough3 from './components/rough3';
import Rough4 from './components/rough4';
import Rough5 from './components/Rough5';
import Rough4MainPage from './components/Rough4MainPage';
function App() {
  return (
<>

<BrowserRouter>
<Header/>
<Routes>
  <Route path='/' element={<Home/>} />
  <Route path='/shopping' element={<Shopping/>} />
  <Route path="/restaurents" element={<Restaurents/>} />
  <Route path="/supplies" element={<Supplies/>} />
  <Route path="/services" element={<Services/>} />
  <Route path="/taxi" element={<Taxi/>} />
  <Route path="/jobs" element={<Jobs/>} />
  <Route path="/career" element={<Career/>} />
  <Route path="/blog" element={<Blog/>} />
  <Route path="/news" element={<News/>} />
  <Route path="/support" element={<Support/>} />
  <Route path="/getapi" element={<Getapi/>} />
  <Route path="/hooks" element={<Hooks/>} />
  <Route path="/rough2" element={<Rough2/>} />
  <Route path="/rough3" element={<Rough3/>} />
  <Route path="/rough4" element={<Rough4/>} />
  <Route path="/rough4mainpage/:id" element={<Rough4MainPage/>} />
  <Route path="/rough5" element={<Rough5/>} />
</Routes>
<Footer/>
</BrowserRouter>

</>
  );
}

export default App;
