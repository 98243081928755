import React from "react";
import { useEffect } from "react";
const Getapi = ()=>{

    const API = "https://primebuk.com/easybukapi/web/job/list";
    
    const fetchApiData = async (url) =>{
        try{
            const res = await fetch(url);
            const data = await res.json();
            console.log(data);
        }catch (error){
            console.log(error)
        }
    } ;

    useEffect(()=>{
        fetchApiData(API);
    },[]);
    return (

        <>
        <h1>Prodcust Details</h1>
        
        </>
    )
}

export default Getapi;