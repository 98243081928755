import React from "react";
import { Carousel } from "react-bootstrap";
import support1 from '../images/support1.jpg';
import support2 from '../images/support2.jpg';
import support3 from '../images/support3.jpg';
import support4 from '../images/support4.jpg';
import {FaRocketchat,FaEnvelope, FaWpforms, FaCodeBranch, FaRandom} from "react-icons/fa";
const Support = ()=>{
    return(
        <>
        <div>
      
      <Carousel fade interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={support1}
            alt="Slide 1"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={support2}
            alt="Slide 2"/>

        </Carousel.Item>

                <Carousel.Item>
          <img
            className="d-block w-100 h-96"
            src={support4}
            alt="Slide 2"
          />

        </Carousel.Item>
        {/* Add more Carousel.Items as needed */}
      </Carousel>
    </div>

<div className="pt-10">
    <p className="text-5xl pb-10 text-center  font-bold">Welcome to Prime Buk Support Page!</p>
    <div className="px-20 text-justify">
<p >


We are here to assist you with any questions, concerns, or issues you may have regarding our wide range of services, including home services, supplies, and taxi booking. Our dedicated support team is committed to ensuring your experience with our platform is smooth and enjoyable. Below, you'll find some helpful information and resources to guide you through common inquiries.</p>

<p className="text-3xl font-semibold">Frequently Asked Questions (FAQs):</p>
<p>Before reaching out to our support team, take a look at our comprehensive FAQ section. It covers a wide range of topics and addresses common queries related to our services, such as pricing, availability, and booking process. You might find the answers you're looking for without delay.</p>

<p className="text-3xl font-semibold">Contacting Support:</p>
<p className="text-xl">If you can't find the information you need in the FAQs, our support team is ready to help you. You can reach us through the following channels:</p>
<div className="flex gap-2">
<FaRocketchat className="text-3xl text-[#17A2B8]"/> 
    <p className="text-2xl font-semibold">Live Chat:</p> 
    </div>


<p>Our live chat feature allows you to connect with a support representative in real-time. Simply click on the chat bubble in the bottom right corner of your screen to start a conversation. We strive to provide prompt responses and assist you with any issues you're facing.</p>
<div className="flex gap-2">
<FaEnvelope className="text-3xl text-[#17A2B8]"/>
<p className="text-2xl font-semibold">Email Support:</p> 
</div>
<p>If you prefer email communication, you can reach us at <span className="text-white bg-[#17A2B8] p-2 rounded-2xl">[support@primebuk.com].</span> Drop us a message detailing your query, and our team will get back to you as soon as possible.</p>
<div className="flex gap-2">
<FaWpforms className="text-3xl text-[#17A2B8]"/>
<p className="text-2xl font-semibold">Reporting Issues:</p>
</div>
<p>We value your feedback and take every issue seriously. If you encounter any technical difficulties, service disruptions, or have concerns about a specific booking, please let us know. You can report problems through our support channels, and we will investigate the matter promptly.</p>
<div className="flex gap-2">
<FaCodeBranch className="text-3xl text-[#17A2B8]"/>
<p className="text-2xl font-semibold">Service Improvements:</p>
</div>
<p>We are continuously working to enhance our services and provide the best possible experience for our users. We appreciate your input and suggestions for improvement. If you have any ideas, feature requests, or general feedback, we encourage you to share them with us. Your feedback helps shape our platform and allows us to meet your expectations better.</p>
<div className="flex gap-2">
<FaRandom className="text-3xl text-[#17A2B8]"/>
<p className="text-2xl font-semibold">Service Updates:</p>
</div>
<p>Stay up-to-date with the latest news, feature releases, and service updates by following our social media channels and subscribing to our newsletter. We regularly share valuable information, special offers, and important announcements to keep you informed.</p>

<p>We understand the importance of reliable support when it comes to using our platform for home services, supplies, and taxi bookings. Our dedicated team is here to assist you every step of the way. Feel free to reach out to us whenever you need assistance, and we'll do our best to ensure your experience with us is exceptional.

</p>
</div>
</div>
    
        </>
    )
}
export default Support;